import { Route, Routes } from "react-router-dom";
// pages
import Homepage from "../container/homepage";
import PrivacyPolicy from "../container/privacyPolicy";
import TermsandCondition from "../container/termsandCondition";
import Faqs from "../container/faqs";
import ContactUs from "../container/contactUs";
import RefundCancellation from "../container/refundandCancellation";
import Page from "../component/404Page/page";

const AllPagesRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Homepage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsandCondition />} />
        <Route path="/faq" element={<Faqs />} />
        <Route path="/refund-cancellation" element={<RefundCancellation />} />
        <Route path="/contact" element={<ContactUs />} />
        {/* 404 page route components */}
        <Route path="*" element={<Page />} />
      </Routes>
    </>
  );
};
export default AllPagesRoutes;
