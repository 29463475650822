// import { useNavigate } from "react-router-dom";
const Banner = () => {
  const dataBox = [
    {
      id: 1,
      title: "1. What is 4everinlove?",
      content:
        "4everinlove is a matrimony app designed to help individuals find their life partners. It provides a platform for people to connect, virtually interact, and build meaningful relationships leading to marriage.",
    },
    {
      id: 2,
      title: "2. How does 4everinlove work?",
      content:
        "4everinlove works by allowing users to create profiles, search for potential matches, and communicate with them. Users can specify their preferences, browse through profiles, and connect with others based on shared interests and compatibility.",
    },
    {
      id: 3,
      title: "3. Is 4everinlove free to use?",
      content:
        "4everinlove offers both free and premium membership options. The free version allows basic profile creation and limited access to features, while the premium membership provides additional benefits and enhanced functionality.",
    },
    {
      id: 4,
      title: "4. How can I create a profile on 4everinlove?",
      content:
        "To create a profile on 4everinlove, download the app from your respective app store, open it, and follow the on-screen instructions to sign up. You will need to provide some basic information and preferences to create your profile.",
    },
    {
      id: 5,
      title: "5. What kind of information is required for creating a profile?",
      content:
        "When creating a profile on 4everinlove, you will typically need to provide details such as your name, age, gender, location, education, occupation, and a brief description about yourself. You may also have the option to add photos and specify your partner preferences.",
    },
    {
      id: 6,
      title: "6. Can I search for matches on my own?",
      content:
        " Yes, 4everinlove allows users to search for matches based on their own preferences. You can apply filters such as age, location, education, occupation, and more to narrow down the search results and find profiles that align with your criteria.",
    },
    {
      id: 7,
      title: " 7. Is my information safe on 4everinlove?",
      content:
        "4everinlove takes privacy and data security seriously. The app employs advanced security measures to protect user information. However, it's always recommended to exercise caution while sharing personal details and avoid sharing sensitive information with strangers.",
    },
    {
      id: 8,
      title: "8. Can I communicate with other users on 4everinlove?",
      content:
        "Yes, once you find a potential match on 4everinlove, you can communicate with them through the app's messaging feature. This allows you to get to know each other better and explore the possibility of a meaningful relationship.",
    },
    {
      id: 9,
      title:
        "9. Can I delete my 4everinlove account if I no longer wish to use it?",
      content:
        "Yes, you have the option to delete your 4everinlove account at any time. Simply go to the app's settings, navigate to the account section, and follow the instructions to deactivate or delete your profile.",
    },
    {
      id: 10,
      title:
        "10. What makes 4everinlove stand out from other matrimonial apps?",
      content:
        "4everinlove distinguishes itself by offering a user-friendly interface, advanced matching algorithms, metaverse experience and a strong emphasis on privacy and security. The app is designed to facilitate genuine connections and foster long-term relationships, making it an ideal choice for those seeking a serious commitment.",
    },
  ];

  // const navigate = useNavigate();
  return (
    <div className="pt-10 ">
      {/* <div className="flex justify-between mx-[4%] md:mx-[6%] lg:mx-[8%] lg:py-16 md:py-10 py-6"> */}
      {/* left side */}
      {/* <div className="flex pt-3 ">
          <div>
            <img
              src={require("../../assets/img/logo-black.png")}
              alt=""
              className="lg:h-[60px] h-[45px] cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        </div> */}
      {/* right side */}
      {/* <div className="flex relative ">
          <div className="mt-3 md:mt-0">
            <button
              className="md:px-7 px-3 md:py-5 py-3 bg-[#FAB8C4] text-[#2522BA] text-[#DE1A31] font-medium font-poppins rounded-[20px]"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </button>
          </div>
        </div> */}
      {/* </div> */}
      {/* main heading */}
      <div className="pt-5 mx-[4%] md:mx-[7%] lg:mx-[14%]">
        <h1 className="lg:text-[80px] text-[50px] text-[#444445] font-bold leading-[96px] font-dm tracking-tight pb-8 text-center">
          FAQs
        </h1>
      </div>
      <div className=" mx-[4%] md:mx-[7%] lg:mx-[14%] py-2 pb-20">
        {dataBox?.map((list, index) => (
          <div key={index}>
            {/* What is 4everinlove  */}
            <h1 className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
              {list.title}
            </h1>
            <ul className="px-5">
              <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
                {list.content}
              </li>
            </ul>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Banner;
