import ReactPlayer from "react-player";
const AboutUs = () => {
  const video =
    "https://public-general-s3-bucket-gng.s3.amazonaws.com/forever-video.mp4";

  return (
    <div className="bg-[#27272E] pt-20 ">
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%]  flex flex-col lg:flex-row justify-between">
        <h1 className="text-[#fff] lg:text-[38px] xl:text-[56px] text-[35px] font-medium font-dm leading-[56px] pt-4 lg:text-left text-center lg:pr-5">
          About Us
        </h1>
        <div className="lg:w-[70%] xl:w-[70%]  ">
          <p className="text-[#fff] lg:text-[18px] text-[15px] font-medium font-dm leading-[20px] py-5 lg:text-left text-center">
            At 4everinlove, we believe that finding your life partner is more
            than just a journey—it's a deeply personal quest filled with hope,
            excitement, and endless possibilities. That's why we're dedicated to
            guiding you every step of the way with care, diligence, and a
            profound understanding of your individual desires and dreams.
            <br />
            <br />
            With our cutting-edge algorithms and personalized approach, each
            match is meticulously curated to resonate with your lifestyle,
            values, and aspirations. Whether you're searching for a soulmate to
            share life's adventures or a kindred spirit to build a future with,
            we're here to make your dreams of lasting love a reality.
            <br />
            <br />
            Join us at 4everinlove and embark on a journey where every
            connection is meaningful, every moment is magical, and every match
            brings you one step closer to your forever love.
          </p>
          {/* <div className="flex lg:justify-start justify-center">
            <button className="lg:px-8 px-6 lg:py-4 py-3 border rounded-[40px] text-[#fff] lg:text-[16px] text-[14px] font-medium font-dm hover:text-[#27272E] hover:bg-[#fff]">
              Show detail — about us
            </button>
          </div> */}
        </div>
      </div>
      {/* </Zoom> */}
      {/* video */}
      <div className=" mx-[4%] md:mx-[6%] lg:mx-[8%] mt-20">
        {/* <video className="w-full" controls>
          <source
            src={require("../../assets/video/video.mp4")}
            type="video/mp4"
          />
        </video> */}
        <ReactPlayer
          url={video}
          controls={false}
          loop
          playing
          playsinline={true}
          muted={true}
          className="video-container"
        />
      </div>
      {/* <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-between py-10">
        <h6 className="text-[#fff] lg:text-[24px] text-[14px] font-medium font-dm leading-[34px] mr-1">
          showcase events 4IL
        </h6>
        <h6 className="text-[#fff] lg:text-[24px] text-[14px] font-medium font-dm leading-[34px] ml-1">
          showcase events 2023
        </h6>
      </div> */}
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-center py-8">
        <img
          src={require("../../assets/img/yellow.png")}
          alt=""
          className="h-[40px]"
        />
      </div>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-end">
        <img
          src={require("../../assets/img/red.png")}
          alt=""
          className="mr-32 relative top-[40px] h-[70px]"
        />
      </div>
    </div>
  );
};
export default AboutUs;
