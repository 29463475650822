// import { useNavigate } from "react-router-dom";
const Banner = () => {
  // const navigate = useNavigate();
  return (
    <div className="pt-10">
      {/* <div className="flex justify-between mx-[4%] md:mx-[6%] lg:mx-[8%] lg:py-16 md:py-10 py-6"> */}
        {/* left side */}
        {/* <div className="flex pt-3 ">
          <div>
            <img
              src={require("../../assets/img/logo-black.png")}
              alt=""
              className="lg:h-[60px] h-[45px] cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        </div> */}
        {/* right side */}
        {/* <div className="flex relative ">
          <div className="mt-3 md:mt-0">
            <button
              className="md:px-7 px-3 md:py-5 py-3 bg-[#FAB8C4] text-[#2522BA] text-[#DE1A31] font-medium font-poppins rounded-[20px]"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </button>
          </div>
        </div> */}
      {/* </div> */}
      {/* main heading */}
      <div className="pt-5  mx-[4%] md:mx-[7%] lg:mx-[14%]">
        <h1 className="lg:text-[80px] text-[50px] text-[#444445] font-bold font-dm tracking-tight pb-8 text-center lg:leading-[85px] leading-[55px]">
          Refund and Cancellation
        </h1>
        <p className="text-[18px] text-[#444445] font-normal font-poppins py-5">
          Thank you for being a valued member of 4EverinLove. We are committed
          to providing you with exceptional experiences and services. This
          Refund Policy outlines our guidelines regarding refunds for our
          products and services.
        </p>
      </div>
      <div className=" mx-[4%] md:mx-[7%] lg:mx-[14%] py-2 pb-20">
        <div>
          {/* Physical Events  */}
          <h1 className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
            1. Physical Events
          </h1>
          <p className="text-[18px] text-[#444445] font-normal font-poppins py-2">
            For Physical events organized by 4EverinLove, the following refund
            policy applies:
          </p>
          <ul className="px-5">
            <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
              1.1{" "}
              <span className="font-semibold">No Refund within 24 Hours:</span>{" "}
              We understand that plans can change, but we are unable to offer
              refunds for cancellations made within 24 hours of the event start
              time.
            </li>
            <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
              1.2{" "}
              <span className="font-semibold">50% Refund within 5 Days:</span>{" "}
              If you cancel your attendance between 24 hours and up to 5 days
              before the event, you may be eligible for a 50% refund of the
              event fee.
            </li>
            <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
              1.3{" "}
              <span className="font-semibold">75% Refund Before 5 Days:</span>{" "}
              Cancellations made more than 5 days before the event will qualify
              for a 75% refund of the event fee.
            </li>
            <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
              1.4 <span className="font-semibold">Refund Request Process:</span>{" "}
              To request a refund for a physical event, please send an email to
              contact@4everinlove.com with the subject line "Event Refund
              Request". In your email, provide your event details, including the
              event name, date, and your contact information. Our team will
              review your request and respond promptly.
            </li>
          </ul>
          {/* Premium Subscriptions */}
        </div>
        <h1 className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
          2. Premium Subscriptions
        </h1>
        <ul className="px-5">
          <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
            2.1{" "}
            <span className="font-semibold">No Refund or Cancellation:</span> We
            do not offer refunds or cancellations for premium subscriptions.
            Once you subscribe to our premium services, the payment is
            non-refundable, and the subscription cannot be cancelled during its
            active period.
          </li>
        </ul>
        {/* Special Circumstances  */}
        <h1 className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
          3. Special Circumstances
        </h1>
        <ul className="px-5">
          <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
            In expceptional cases, we may consider refunds outside the specified
            refund windows if there are compelling reasons. Please contact us at
            contact@4everinlove.com with details of your situation, and our team
            will assess your request on a case-by-case basis.
          </li>
        </ul>
        {/* Data Security */}
        <h1 className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
          4. Changes to this Policy
        </h1>
        <ul className="px-5">
          <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
            4.1 <span className="font-semibold">Policy Updates:</span>{" "}
            4EverinLove reserves the right to modify this refund policy at any
            time. Any changes to this policy will be communicated to users
            through our website or other appropiate means.
          </li>
        </ul>
        {/* Children's Privacy */}
        <h1 className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
          5. Contact Us
        </h1>
        <ul className="px-5">
          <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
            If you have any questions or concerns regarding this refund policy
            or need assistance with refund requests, please contact our customer
            support team at contact@4everinlove.com.
          </li>
        </ul>
        {/* Changes to the Privacy Policy */}
        <h1 className="text-[20px] text-[#444445] font-semibold font-poppins py-2">
          Conclusion
        </h1>
        <ul className="px-5 ">
          <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
            By using 4everinlove and engaging in our products and services, you
            acknowledge and agree to abide by this refund policy. We appreciate
            your understanding and coopertion.
          </li>
          <li className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
            MS MATRIMONY PRIVATE LIMITED <br />
            E-53, BARSATI FLOOR, ROAD NO.1 F-BLOCK. GREATER KAILASH ENCLAVE I
            BEHIND HANS RAJ GUPTA MARG, NEW DELHI, 110048 <br />
            contact@4everinlove.com
          </li>
        </ul>
      </div>
    </div>
  );
};
export default Banner;
