import Banner from "../../component/homepage/banner";
import AboutUs from "../../component/homepage/aboutUs";
import OurValue from "../../component/homepage/ourValue";
import Metaverse from "../../component/homepage/metaverse";
// import Events from "../../component/homepage/events";
import AppInfo from "../../component/homepage/appInfo";
import Footer from "../../shared/footer";

const Homepage = () => {
  return (
    <>
      <Banner />
      <AboutUs />
      <OurValue />
      <Metaverse />
      {/* <Events /> */}
      <AppInfo />
      <Footer />
    </>
  );
};
export default Homepage;
