// import { useNavigate } from "react-router-dom";
const Banner = () => {
  const contentBox = [
    {
      id: 1,
      title: "1. Eligibility",
      content: [
        "1.1 You must be at least 18 years old or the age of majority in your jurisdiction to use the App.",
        "1.2 By using the App, you represent and warrant that you have the right, authority, and capacity to enter into this Agreement.",
      ],
    },
    {
      id: 2,
      title: "2. User Accounts",
      content: [
        "2.1 You may need to create a user account to access certain features of the App.",
        "2.2 You are responsible for maintaining the confidentiality of your account information, including your username and password.",
        "2.3 You agree to provide accurate and up-to-date information when creating your user account.",
      ],
    },
    {
      id: 3,
      title: "3. User Content",
      content: [
        "3.1 You are solely responsible for any content you post, upload, or transmit through the App.",
        "3.2 You retain ownership of the content you submit, but grant the Company a worldwide, non-exclusive, royalty-free license to use, reproduce, distribute, and display your content in connection with the App.",
        "3.3 You agree not to post, upload, or transmit any content that is illegal, obscene, defamatory, discriminatory, or infringes upon any third-party rights.",
      ],
    },
    {
      id: 4,
      title: "4. Privacy and Data Protection",
      content: [
        "4.1 The Company respects your privacy and handles your personal information in accordance with its Privacy Policy.",
        "4.2 By using the App, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.",
      ],
    },
    {
      id: 5,
      title: "5. Prohibited Activities",
      content: [
        " 5.1 You agree not to engage in any of the following prohibited activities:",
      ],
      content2: [
        "a - Violating any applicable laws or regulations.",
        "b - Impersonating any person or entity or falsely representing your affiliation with any person or entity.",
        "c - Harassing, stalking, or threatening other users.",
        "d - Interfering with or disrupting the operation of the App.",
        "e - Uploading or distributing viruses or other malicious code.",
        "f - Attempting to gain unauthorized access to the App's systems or networks.",
      ],
    },
    {
      id: 6,
      title: "6. Third-Party Content and Links",
      content: [
        "6.1 The App may include links to third-party websites or services.",
        "6.2 The Company does not endorse or assume any responsibility for any third-party content or services.",
      ],
    },
    {
      id: 7,
      title: "7. Termination",
      content: [
        "7.1 The Company reserves the right to suspend or terminate your access to the App at any time and for any reason.",
        "7.2 You may also terminate this Agreement by discontinuing your use of the App.",
        "7.3 Upon termination, all provisions of this Agreement which by their nature should survive, including but not limited to ownership provisions, warranty disclaimers, and limitations of liability, shall continue to be in effect.",
      ],
    },
    {
      id: 8,
      title: "8. Disclaimer of Warranties",
      content: [
        "8.1 The App is provided on an 'as is' and 'as available' basis without warranties of any kind, whether express or implied.",
        "8.2 The Company does not warrant that the App will be error-free, uninterrupted, secure, or meet your specific requirements.",
      ],
    },
    {
      id: 9,
      title: "9. Limitation of Liability",
      content: [
        " 9.1 In no event shall the Company be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the App.",
        "",
      ],
    },
  ];

  // const navigate = useNavigate();
  return (
    <div className="pt-10 ">
      {/* <div className="flex justify-between mx-[4%] md:mx-[6%] lg:mx-[8%] lg:py-16 md:py-10 py-6"> */}
      {/* left side */}
      {/* <div className="flex pt-3 ">
          <div>
            <img
              src={require("../../assets/img/logo-black.png")}
              alt=""
              className="lg:h-[60px] h-[45px] cursor-pointer"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        </div> */}
      {/* right side */}
      {/* <div className="flex relative ">
          <div className="mt-3 md:mt-0">
            <button
              className="md:px-7 px-3 md:py-5 py-3 bg-[#FAB8C4] text-[#2522BA] text-[#DE1A31] font-medium font-poppins rounded-[20px]"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </button>
          </div>
        </div> */}
      {/* </div> */}
      {/* main heading */}
      <div className="pt-5  mx-[4%] md:mx-[7%] lg:mx-[14%]">
        <h1 className="xl:text-[80px] lg:text-[72px] text-[50px] text-[#444445] font-bold  font-dm tracking-tight pb-8 text-center lg:leading-[85px] leading-[55px]">
          Terms and Conditions
        </h1>
        <p className="text-[18px] text-[#444445] font-normal font-poppins py-5">
          These Terms and Conditions govern your use of the 4EverInLove
          Matrimony App provided by 4EverInLove. By accessing or using the App,
          you agree to be bound by these Terms and Conditions. If you do not
          agree with any part of these Terms and Conditions, you must not use
          the App.
        </p>
      </div>
      <div className=" mx-[4%] md:mx-[7%] lg:mx-[14%] py-2 pb-10">
        {contentBox?.map((list, index) => (
          <div key={index}>
            {/* Eligibility  */}
            <h1 className="text-[18px] text-[#444445] font-semibold font-poppins py-2">
              {list.title}
            </h1>
            {list?.content?.map((item, index) => (
              <ul className="px-5" key={index}>
                <li className="text-[18px] text-[#444445] font-normal font-poppins py-2">
                  {item}
                </li>
                {list?.content2?.map((data, index) => (
                  <ul className="pl-2" key={index}>
                    <li className="text-[18px] text-[#444445] font-normal font-poppins py-1">
                      {data}
                    </li>
                  </ul>
                ))}
              </ul>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};
export default Banner;
