// import { Zoom } from "react-awesome-reveal";
const OurValue = () => {
  return (
    <div className="py-20 bg-[#fff]">
      <div className=" lg:ml-[16%] lg:flex lg:flex-row flex-col justify-between">
        {/* right side content */}
        <div className="xl:w-[34%] lg:w-[50%] w-[100%] lg:pt-20 pt-8 px-[20px] py-[10px] lg:px-0 lg:py-0">
          <h1 className="lg:text-[55px] xl:text-[70px] 2xl:text-[84px] text-[40px] lg:text-left text-center text-[#292930] font-medium font-dm xl:leading-[82px] leading-[initial] py-5 tracking-tight">
            Why Choose
            <div className="flex lg:justify-start justify-center pb-4">
              <span className="font-MelodySunday font-light flex items-center text-[70px] leading-[0px] relative">
                <div className="w-20 h-20 rounded-full bg-[#FFDC60] mt-8 absolute z-20"></div>
                <span className="relative z-50 pt-12">4everinlove?</span>
              </span>
            </div>
          </h1>
          <p className="lg:text-[18px] text-[16px] text-[#999FAE] font-poppins font-normal leading-[27px] py-5 lg:text-left text-center">
            Our personalized matchmaking, cutting-edge algorithms, and
            unwavering commitment to privacy make us your ultimate destination
            for finding your perfect life partner.
          </p>
          <p className="lg:text-[18px] text-[16px] text-[#999FAE] font-poppins font-normal leading-[27px] py-5 lg:text-left text-center">
            From exclusive community events to inspiring success stories, we've
            got everything you need to make your happily ever after a reality!
          </p>
        </div>
        <div className="lg:w-[57%] w-[100%] flex justify-center lg:items-end">
          <img src={require("../../assets/img/our-value.png")} alt="" />
        </div>
      </div>

      {/* card  */}
      <div className="flex flex-wrap lg:flex-row flex-col justify-center xl:ml-[8%] xl:mr-0 md:mx-[4%] mx-[2%]  lg:mt-[-25px] ">
        {/* box 1 */}
        <div className="xl:w-3/12 lg:w-4/12 bg-[#FF97A1] lg:ml-7 lg:ml-4 m-4 flex flex-col  items-center pt-10 rounded-[24px]">
          <div className="lg:w-72 w-56 lg:h-72 h-56 bg-[#FAB8C4] mb-6 rounded-full flex justify-center items-center ">
            <img
              src={require("../../assets/img/convex.png")}
              alt="loading..."
              className=" lg:h-[140px] h-[100px] "
            />
          </div>
          <div className="bg-[#fff] lg:mx-3 m-3 py-8 px-5 2xl:px-8 rounded-[24px] 2xl:h-[420px] xl:h-[440px] lg:h-[415px] md:w-[97%] w-[94%] lg:w-[94%]">
            <h1 className="lg:text-[45px] text-[35px] text-[#DDDDE8] font-dm font-medium leading-[45px] lg:mb-2">
              01
            </h1>
            <h3 className="lg:text-[32px] text-[24px] leading-[38px] text-[#292930] font-dm font-medium ">
              Personalized Matchmaking
            </h3>
            <p className="2xl:text-[16px] lg:text-[15px] text-[14px] text-[#999FAE] font-poppins font-normal leading-[24px] pt-3 pb-5">
              Our advanced algorithms and personalized approach ensure that
              every match is thoughtfully curated to align with your lifestyle,
              values, and aspirations. We take the time to understand your
              unique preferences and desires, so you can connect with someone
              who truly complements you.
            </p>
          </div>
        </div>
        {/* box 2 */}
        <div className="xl:w-3/12 lg:w-4/12 bg-[#EF8E4B] lg:mx-7 lg:mx-4 m-4 flex flex-col  items-center pt-10 rounded-[24px] ">
          <div className="lg:w-72 w-56 lg:h-72 h-56 bg-[#DE1A31] mb-6 rounded-full flex justify-center items-center ">
            <img
              src={require("../../assets/img/humb.png")}
              alt="loading..."
              className=" lg:h-[180px] ml-[-3px] mt-2 h-[140px] "
            />
          </div>
          <div className="bg-[#fff] lg:mx-3 m-3 py-8 px-5 2xl:px-8 rounded-[24px] 2xl:h-[420px] xl:h-[440px] lg:h-[415px] md:w-[97%] w-[94%] lg:w-[94%]">
            <h1 className="lg:text-[45px] text-[35px] text-[#DDDDE8] font-dm font-medium leading-[45px] lg:mb-2">
              02
            </h1>
            <h3 className="lg:text-[32px] text-[24px] leading-[38px] text-[#292930] font-dm font-medium ">
              Metaverse
            </h3>
            <p className="2xl:text-[16px] lg:text-[15px] text-[14px] text-[#999FAE] font-poppins font-normal leading-[24px]  pt-3 pb-5">
              Immerse yourself in our virtual dating experience through
              metaverse integration. Explore virtual environments, attend
              interactive events, and connect with potential matches in a
              dynamic and innovative way.
            </p>
          </div>
        </div>
        {/* box 3 */}
        <div className="xl:w-3/12 lg:w-4/12 bg-[#BE89E0] lg:mx-7 lg:mx-4 m-4 flex flex-col items-center pt-10 rounded-[24px]">
          <div className="lg:w-72 w-56 lg:h-72 h-56 bg-[#FC9090] mb-6 rounded-full flex justify-center items-center ">
            <img
              src={require("../../assets/img/phone.png")}
              alt="loading..."
              className=" lg:h-[180px]  h-[140px]"
            />
          </div>
          <div className="bg-[#fff] lg:mx-3 m-3 py-8 px-5 2xl:px-8 rounded-[24px] 2xl:h-[420px] xl:h-[440px] lg:h-[415px] md:w-[97%] w-[94%] lg:w-[94%]">
            <h1 className="lg:text-[45px] text-[35px] text-[#DDDDE8] font-dm font-medium leading-[45px] lg:mb-2">
              03
            </h1>
            <h3 className="lg:text-[32px] text-[24px] leading-[38px] text-[#292930] font-dm font-medium ">
              In-Person Events
            </h3>
            <p className="2xl:text-[16px] lg:text-[15px] text-[14px] text-[#999FAE] font-poppins font-normal leading-[24px]  pt-3 pb-5">
              Participate in events, workshops, and seminars designed to help
              you enhance your dating skills, boost your confidence, and connect
              with other singles in a fun and interactive environment.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OurValue;
