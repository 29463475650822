const Metaverse = () => {
  return (
    <div className=" mx-[4%] md:mx-[4%] lg:mx-[5%] my-5">
      <div className="flex lg:flex-row flex-col justify-between metaverse_img  ">
        <div className="xl:w-[50%] lg:w-[53%] w-[100%] flex flex-col justify-center xl:pl-32 lg:pl-20 pl-0 md:pt-32 pt-16 lg:pt-0">
          <h1 className="xl:text-[78px] md:text-[48px] text-[40px] font-medium text-[#292930] font-dm xl:leading-[65px] lg:leading-[55px] leading-[50px] tracking-tighter lg:text-left text-center px-5 lg:px-0">
            Find your Special <br className="lg:block hidden" />
            <div className="flex lg:justify-start justify-center">
              {/* <span>in</span> */}

              <span className="font-MelodySunday font-light xl:text-[90px] lg:text-[75px] md:text-[60px] text-[45px] lg:mt-10  z-10">
                Someone.
              </span>
            </div>
          </h1>
          <p className="text-[16px] font-normal text-[#292930] leading-[24px] font-poppins xl:py-10 lg:py-5 lg:text-left text-center px-5 lg:px-0 py-3">
            Access quick searches, instant updates, and a seamless user
            experience—all from your phone. Stay connected and engaged with your
            matrimonial journey wherever you go.
          </p>
          <p className="text-[16px] font-normal text-[#292930] leading-[24px] font-poppins lg:text-left text-center px-5 lg:px-0 py-3">
            Join 4everinlove for mobile-friendly matchmaking on the move.
          </p>
          {/* <div className="xl:py-10 lg:py-4 flex  md:flex-row flex-col items-center lg:justify-start justify-center">
            <div
              onClick={() =>
                window.open(
                  "https://apps.apple.com/ae/app/4everinlove/id6468990355"
                )
              }
            >
              <img
                src={require("../../assets/img/app.png")}
                alt=""
                className=" xl:h-[55px] xl:w-[200px] md:w-[160px] w-[130px] md:h-[55px] h-[40px] cursor-pointer"
              />
            </div>
            <div
              className="md:ml-4"
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.f4everinlove.off"
                )
              }
            >
              <img
                src={require("../../assets/img/google.jpg")}
                alt=""
                className="xl:h-[55px] xl:w-[200px] md:w-[160px] w-[130px] md:h-[55px] h-[40px] cursor-pointer rounded-lg md:mt-0 mt-2"
              />
            </div>
          </div> */}
        </div>
        <div className="xl:w-[50%] lg:w-[55%] w-full flex justify-center px-12 lg:pl-0 lg:pt-2 lg:pb-0 py-10 ">
          <div className="lg:block hidden">
            <img src={require("../../assets/img/website.png")} alt="" />
          </div>
          <div className="lg:hidden block" >
            <img src={require("../../assets/img/website2.png")} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Metaverse;
