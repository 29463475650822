import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="py-10 mx-[4%] md:mx-[7%] lg:mx-[14%]">
      <div className="flex flex-wrap lg:flex-row justify-between lg:justify-center py-10"></div>
      {/* footer 2 */}
      <div className=" pt-8 border-t-2 ">
        <div className=" ">
          <img
            src={require("../../src/assets/img/red-transparent.png")}
            alt=""
            className="md:h-[65px] h-[45px]"
          />
        </div>
        <ul className="flex flex-wrap md:flex-row flex-col justify-start pt-4 pb-3">
          <li
            className="text-[14px] font-normal font-dm text-[#999FAE] cursor-pointer md:pr-3 pr-0"
            onClick={() => {
              window.open("/privacy-policy", "_blank");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Privacy Policy
          </li>
          <ul className="flex items-center">
            <li className="hidden md:block md:border-l h-[20px] "></li>
          </ul>
          <li
            className="text-[14px] font-normal font-dm text-[#999FAE] cursor-pointer md:px-3 px-0"
            onClick={() => {
              window.open("/terms-and-conditions", "_blank");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Terms and Conditions
          </li>
          <ul className="flex items-center">
            <li className="hidden md:block md:border-l border-l h-[20px] "></li>
          </ul>
          <li
            className="text-[14px] font-normal font-dm text-[#999FAE] cursor-pointer md:px-3 px-0 "
            onClick={() => {
              window.open("/faq", "_blank");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            FAQs
          </li>
          <ul className="flex items-center">
            <li className="hidden md:block md:border-l border-l h-[20px] "></li>
          </ul>
          <li
            className="text-[14px] font-normal font-dm text-[#999FAE] cursor-pointer md:px-3 px-0 "
            onClick={() => {
              window.open("/refund-cancellation", "_blank");
              window.scrollTo({ top: 0, behavior: "smooth" });
            }}
          >
            Refund and Cancellation
          </li>
        </ul>
        <div className="lg:flex justify-between">
          <h3 className="text-[14px] text-[#999FAE] font-medium font-dm">
            © 2023, M S MATRIMONY PRIVATE LIMITED
          </h3>

          <div
            className="flex pt-4 lg:pt-0"
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <img
              src={require("../../src/assets/img/scroll.png")}
              alt=""
              className="h-[24px] mr-3 cursor-pointer"
            />
            <h6 className="text-[14px] text-[#999FAE] font-medium font-dm cursor-pointer">
              Back to top
            </h6>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Footer;
