import { useNavigate } from "react-router-dom";
const Page = () => {
  const navigate = useNavigate();
  return (
    <div className="h-[100vh]  ">
      <div className="flex justify-center py-20 ">
        <div className="lg:px-64 md:px-32 px-20 lg:py-24 md:py-12 py-20 rounded-lg shadow-md bg-[#FFDBDB]">
          <h1 className="text-center md:text-[60px] text-[45px] font-bold text-[#292930] font-dm leading-[60px]">
            404
          </h1>
          <p className="text-center md:text-[16px] text-[14px] text-[#292930] font-normal font-dm">
            Page is Not Found!
          </p>
          <div
            className="flex justify-center py-3"
            onClick={() => {
              navigate("/");
            }}
          >
            <button className="px-5 py-2 md:text-[14px] text-[12px] shadow-md text-[#292930] font-medium rounded-[90px] font-dm bg-[#fff]">
              Back To Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Page;
