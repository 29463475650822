import { useNavigate } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const Banner = () => {
  const navigate = useNavigate();
  return (
    <div className="banner_img ">
      {/* left side image */}
      <div className="hidden lg:block">
        <LazyLoadImage
          src={require("../../assets/img/banner-webp.png")}
          alt=""
          className="absolute right-0 lg:top-[100px] xl:top-[-3px] lg:h-[900px] xl:h-[1100px] 2xl:h-[1150px] homepage-img"
        />
      </div>
      <div className="flex justify-between mx-[4%] md:mx-[6%] lg:mx-[8%] lg:py-16 md:py-10 py-6">
        {/* left side */}
        <div className="flex  ">
          {/* <h4 className="text-[28px] font-poppins font-semibold px-6 ">LOGO</h4> */}
          <div className="xl:block hidden">
            <img
              src={require("../../assets/img/4-transparent.png")}
              alt=""
              className="h-[65px] "
            />
          </div>
          <div className="xl:hidden block md:pt-0 pt-2">
            <img
              src={require("../../assets/img/logored2.png")}
              alt=""
              className="md:h-[65px] h-[50px] "
            />
          </div>
        </div>
        {/* right side */}
        <div className="flex relative ">
          <div className="mt-3 md:mt-0">
            <button
              className="md:px-7 px-3 md:py-5 py-3 bg-[#FAB8C4] text-[#DE1A31] font-medium font-poppins rounded-[20px]"
              onClick={() => {
                navigate("/contact");
              }}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
      {/* main content */}
      <div className="mt-36 lg:pl-40 lg:pr-10 mx-[4%] md:mx-[5%] lg:mx-[8%] hidden lg:block">
        <h1 className="text-[96px] text-[#292930] font-bold leading-[96px] font-dm tracking-tight">
          Find{" "}
          <span className="font-MelodySunday font-light absolute ml-[-18px] mt-[44px]">
            Love
          </span>{" "}
          <br />
          <span className="flex">
            Like <div className="w-20 h-2 mt-[75px] ml-2 bg-[#DE1A31]"></div>
          </span>
          Never
          <br />{" "}
          <span className="flex">
            Before
            <div className="w-4 h-4 mt-[68px] ml-2 bg-[#DE1A31] rounded-full"></div>
          </span>
        </h1>
        <p className="text-[18px] text-[#999FAE] font-normal font-poppins py-5">
          Embark on a Timeless Love Story
        </p>
      </div>

      {/* mobile */}
      <div className="mt-36 lg:pl-40 lg:pr-10 mx-[4%] md:mx-[5%] lg:mx-[8%]  lg:hidden">
        <h1 className="sm:text-[76px] text-[65px]  text-[#292930] font-bold sm:leading-[80px] leading-[70px] font-dm tracking-tight text-center ">
          <span className="sm:ml-[-40px] ml-[-56px]">Find</span>
          <span className=" font-MelodySunday font-light absolute ml-[-14px] sm:mt-[34px] mt-[30px]">
            Love
          </span>{" "}
          <div className="flex justify-center">
            <span>Like</span>
            <div className="w-12 h-[4px] ml-2 sm:mt-[60px] mt-[40px] bg-[#DE1A31]"></div>
          </div>
          Never
          <span className="flex justify-center">
            Before
            <div className="w-2 h-2  mt-[58px] ml-1 bg-[#DE1A31] rounded-full"></div>
          </span>
        </h1>
        <p className="text-[15px] text-[#999FAE] font-normal font-poppins py-5 text-center">
          Embark on a Timeless Love Story
        </p>
      </div>
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] lg:flex lg:flex-row lg:justify-start flex flex-col justify-center lg:pl-40 py-4 ">
        <div
          className="flex justify-center cursor-pointer"
          onClick={() =>
            window.open(
              "https://apps.apple.com/ae/app/4everinlove/id6468990355"
            )
          }
        >
          <img
            src={require("../../assets/img/app.png")}
            alt=""
            className="mt-2 lg:w-[220px] lg:h-[65px] md:w-[200px] w-[140px] md:h-[58px] h-[45px]  "
          />
        </div>
        <div
          className="flex justify-center cursor-pointer"
          onClick={() =>
            window.open(
              "https://play.google.com/store/apps/details?id=com.f4everinlove.off"
            )
          }
        >
          <img
            src={require("../../assets/img/google.jpg")}
            alt=""
            className="mx-2 mt-2 lg:w-[220px] lg:h-[64px] md:w-[200px] w-[140px] md:h-[58px] h-[45px] rounded-lg "
          />
        </div>
        <div className="flex justify-center">
          <div className="w-7 h-7 bg-[#DE1A31] rounded-full mt-8 ml-3  "></div>
        </div>
      </div>
      {/* <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex lg:justify-start justify-center lg:pl-40 lg:pt-40 lg:pb-10 py-10  ">
        <img
          src={require("../../assets/img/scroll.png")}
          alt=""
          className="h-8 cursor-pointer"
        />
        <h6 className="text-[16px] text-[#999FAE] font-normal px-5 pt-1 ">
          Scroll to explore
        </h6>
      </div> */}
      {/* bottom round img */}
      <div className="mx-[4%] md:mx-[6%] lg:mx-[8%] flex justify-center">
        <img
          src={require("../../assets/img/circle-b.png")}
          alt=""
          className="relative lg:top-[80px] top-[45px] lg:left-[160px] lg:h-[160px] h-[100px]"
        />
      </div>
    </div>
  );
};
export default Banner;
