import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { notification, Spin } from "antd";
import axios from "axios";

const Banner = () => {
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  // state to save data
  const [loader, setLoader] = useState(false);
  // notification
  const openNotification = (message, description) => {
    api.open({
      message: message,
      description: description,
    });
  };
  // contact data
  const [contactData, setContactData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  // submit handler
  const submitHandler = (e) => {
    e.preventDefault();
    setLoader(true);
    axios
      .post(
        "https://api.gngtest.site/api/submit-contact-us-request?locale=en",
        contactData
      )
      .then((response) => {
        setLoader(false);
        setContactData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
        openNotification("Success", "Message send successfully");
      })
      .catch((error) => {
        console.log(error);
        setLoader(false);
        openNotification("Error", "");
      });
  };

  // onchange handler to update the state
  const onChangeHandler = (key, value) => {
    let duplicate = { ...contactData };
    duplicate[key] = value;
    setContactData(duplicate);
  };

  return (
    <div className="banner_img ">
      {/* context holder only for popup */}
      {contextHolder}
      {/*  */}
      <div className="flex justify-between mx-[3%] md:mx-[4%] lg:mx-[8%] lg:py-16 md:py-10 py-6">
        {/* left side */}
        <div className="flex  ">
          <div className="xl:block hidden">
            <img
              src={require("../../assets/img/4-transparent.png")}
              alt=""
              className="h-[65px] cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="xl:hidden block md:pt-0 pt-2">
            <img
              src={require("../../assets/img/logored2.png")}
              alt=""
              className="md:h-[65px] h-[50px] cursor-pointer "
              onClick={() => navigate("/")}
            />
          </div>
        </div>
      </div>
      {/* form */}
      <div className="mx-[4%] md:mx-[7%] lg:mx-[14%] py-6">
        <div class="w-full md:bg-white rounded lg:px-8 px-4 pt-6 pb-8 mb-4">
          <h1 className="lg:text-[96px] text-[50px] text-[#292930] font-bold  font-dm tracking-tight pb-8 text-center">
            Contact Us
          </h1>
          <form
            class=" lg:px-8 px-4 pt-6 pb-8 mb-4"
            onSubmit={(e) => submitHandler(e)}
          >
            <div class="mb-6">
              <label
                class="font-medium font-poppins text-[16px] text-[#292930] mb-2"
                for="username"
              >
                Name
              </label>
              <input
                class="shadow appearance-none border rounded font-poppins text-[14px] w-full py-2 px-3 text-gray-500 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Enter Your Name"
                required
                onChange={(e) => {
                  onChangeHandler("name", e.target.value);
                }}
                value={contactData?.name}
              />
            </div>
            <div class="mb-6">
              <label class="font-medium font-poppins text-[16px] text-[#292930] mb-2">
                Email
              </label>
              <input
                class="shadow appearance-none border font-poppins text-[14px] rounded w-full py-2 px-3 text-gray-700  leading-tight focus:outline-none focus:shadow-outline"
                type="email"
                placeholder="Enter Your Email"
                required
                onChange={(e) => {
                  onChangeHandler("email", e.target.value);
                }}
                value={contactData?.email}
              />
            </div>
            <div class="mb-6">
              <label class="font-medium font-poppins text-[16px] text-[#292930] mb-2">
                Phone
              </label>
              <input
                class="shadow appearance-none border font-poppins text-[14px] rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                type="number"
                placeholder="Enter Your Number"
                required
                onChange={(e) => {
                  onChangeHandler("phone", e.target.value);
                }}
                value={contactData?.phone}
              />
            </div>
            <div class="mb-6">
              <label class="font-medium font-poppins text-[16px] text-[#292930] mb-2">
                Message
              </label>
              <textarea
                class="shadow appearance-none border font-poppins text-[14px] rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
                type="text"
                placeholder="Enter Your Mobile Number"
                rows={8}
                required
                onChange={(e) => {
                  onChangeHandler("message", e.target.value);
                }}
                value={contactData?.message}
              />
            </div>
            <div class="flex justify-center">
              <button
                class="font-bold bg-[#FAB8C4] text-[#2522BA] font-medium font-poppins  py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                type="submit"
              >
                {loader ? <Spin /> : "Submit"}
              </button>
            </div>
          </form>
        </div>{" "}
      </div>{" "}
      <div className="border-t">
        <div className="flex flex-col lg:flex-row justify-between py-10 mx-[3%] md:mx-[4%] lg:mx-[8%]">
          <div className="w-full lg:w-[30%] flex flex-col items-center">
            <img
              src={require("../../assets/img/phone-call.png")}
              alt=""
              className="h-[45px] "
            />

            <h4 className=" font-poppins text-[14px] py-2 text-center">
              <b>Phone:</b>
              <br /> +91-9811210004
            </h4>
          </div>
          <div className="w-full lg:w-[30%] flex flex-col items-center ">
            <img
              src={require("../../assets/img/gps.png")}
              alt=""
              className="h-[45px] "
            />

            <h4 className="font-poppins text-[14px] py-2 text-center">
              <b>Address:</b>
              <br />
              MS MATRIMONY PRIVATE LIMITED E-53, BARSATI FLOOR, ROAD NO.1
              F-BLOCK. GREATER KAILASH ENCLAVE I BEHIND HANS RAJ GUPTA MARG, NEW
              DELHI, 110048
            </h4>
          </div>
          <div className="w-full lg:w-[30%] flex flex-col items-center">
            <img
              src={require("../../assets/img/email.png")}
              alt=""
              className="h-[45px] "
            />

            <h4 className="font-poppins text-[14px] py-2 text-center">
              <b>Email:</b>
              <br /> contact@4everinlove.com
            </h4>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Banner;
