const AppInfo = () => {
  return (
    <div className="bg-[#fff] mx-[4%] md:mx-[4%] lg:mx-[5%] relative">
      <div className="absolute 2xl:pt-[90px] xl:pt-[73px] lg:pt-[35px] md:pt-[40px] sm:pt-[35px] pt-[25px] z-10 w-full flex flex-col items-center ">
        <h1 className="2xl:text-[80px] xl:text-[60px] md:text-[45px] sm:text-[30px] text-[23px]  xl:leading-[80px] lg:leading-[50px] md:leading-[40px] leading-[30px] font-dm font-medium text-[#fff] text-center">
          What are you waiting for?
        </h1>
        <div>
          <h6
            className="lg:text-[18px] md:text-[16px] text-[14px] font-normal text-[#fff] font-poppins 2xl:pt-4 xl:pt-2  lg:pt-2
        md:pt-4 sm:pt-2 pt-1 text-center"
          >
            Download the app now
          </h6>
          <div className="flex xl:mt-4 lg:mt-3 mt-1">
            <div
              className="cursor-pointer"
              onClick={() =>
                window.open(
                  "https://apps.apple.com/ae/app/4everinlove/id6468990355"
                )
              }
            >
              <img
                src={require("../../assets/img/app.png")}
                alt=""
                className="xl:h-[58px] lg:h-[55px] xl:w-[200px] lg:w-[170px] md:w-[130px] sm:w-[110px] w-[95px]  md:h-[35px] sm:h-[28px] h-[25px] "
              />
            </div>
            <div
              className="cursor-pointer lg:ml-4 ml-2 "
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=com.f4everinlove.off"
                )
              }
            >
              <img
                src={require("../../assets/img/google.jpg")}
                alt=""
                className="xl:h-[58px] lg:h-[55px] xl:w-[200px]  lg:w-[170px] md:w-[130px] sm:w-[110px] w-[95px]  md:h-[35px] sm:h-[28px] h-[25px] rounded-lg"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="w-full ">
        <div className="flex justify-center  rounded-[50px]">
          <div className="w-[800px] absolute mt-[500px] ml-[400px] rounded-[50px] responsive-screen">
            {/* <div className="image-fader">
              <img
                src={require("../../assets/img/screen4.png")}
                alt=""
                className="h-[760px] w-[364px] ml-6  mt-8 px-[0.5rem] rounded-[50px]"
              />
              <img
                src={require("../../assets/img/screen2.png")}
                alt=""
                className="h-[760px] w-[364px] ml-6  mt-8 px-[0.5rem] rounded-[50px]"
              />
              <img
                src={require("../../assets/img/screen5.png")}
                alt=""
                className="h-[760px] w-[364px] ml-6  mt-8 px-[0.5rem] rounded-[50px]"
              />
              <img
                src={require("../../assets/img/screen1.png")}
                alt=""
                className="h-[760px] w-[364px] ml-6 mt-8 px-[0.5rem] rounded-[50px]"
              />
            </div> */}
            <img
              src={require("../../assets/img/mobileapp2.png")}
              alt=""
              className=""
            />
          </div>
        </div>
        <img
          src={require("../../assets/img/app-new.png")}
          alt=""
          className="w-full"
        />
      </div>
    </div>
  );
};
export default AppInfo;
